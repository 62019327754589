import { zodResolver } from "@hookform/resolvers/zod";
import { notification } from "antd";
import { AppContext } from "AppContextProvider";
import { conduktClient } from "clients/condukt";
import { useContext, useState } from "react";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { z } from "zod";
import config from "../../config";
import { ContactUsLink } from "./ContactUsLink";
import { FlipButton } from "./FlipButton";
import { Input } from "./Input";
import { PasswordInput } from "./PasswordInput";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "./Tooltip";

const validationSchema = z.object({
  username: z
    .string()
    .min(1, "Please enter your email")
    .email("Please enter a valid email"),
  password: z.string().min(1, "Please enter your password"),
});

const ErrorIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 16"
      className={className}
    >
      <g
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.2"
        clipPath="url(#a)"
      >
        <path d="M7.99992 14.6663c3.68188 0 6.66668-2.9847 6.66668-6.66663 0-3.68189-2.9848-6.66666-6.66668-6.66666-3.6819 0-6.66667 2.98477-6.66667 6.66666 0 3.68193 2.98477 6.66663 6.66667 6.66663ZM8 5v3M8 10.667h.00667" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

const Login = () => {
  const [loading, setLoading] = useState(false);
  const { setJwt } = useContext(AppContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      username: "",
      password: "",
    },
    mode: "onTouched",
  });

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const res = await conduktClient.logIn(data.username, data.password);
      conduktClient.refreshToken = res?.access_token?.token_plaintext;
      localStorage.setItem("refreshToken", res?.access_token?.token_plaintext);
      localStorage.setItem("username", res?.user_name);
      setJwt(await conduktClient.getJWTToken());

      window.location.reload();
    } catch (error) {
      notification.error({ message: "Unable to log in" });
    }
    setLoading(false);
  };

  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Rethink+Sans:wght@400..800&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <div className="flex min-h-screen flex-col bg-background text-foreground font-sans">
        <header className="border-b border-background-dark">
          <div className="w-full px-5 lg:px-8 py-8">
            <a href={config.CONDUKT_WEBSITE_URL} target="_blank">
              <img src="/logo.svg" alt="Condukt Logo" height={18} />
            </a>
          </div>
        </header>
        <main className="relative isolate flex flex-1 flex-col items-center justify-center">
          <div className="max-w-max w-full px-5 lg:px-8 py-30 text-center">
            <h1 className="mb-12 text-2xl">
              <span className="text-grey">Welcome to Condukt!</span>
              <br />
              <span>Enter your credentials to continue</span>
            </h1>

            <form
              onSubmit={handleSubmit(onSubmit)}
              className="mx-auto flex flex-col space-y-4 text-left"
            >
              <div>
                <Input
                  {...register("username")}
                  label="Business Email"
                  id="email"
                  aria-invalid={!!errors.username}
                  aria-describedby="username-error"
                  containerClassName="pr-4"
                >
                  {errors.username && (
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger>
                          <ErrorIcon className="text-error size-4" />
                        </TooltipTrigger>
                        <TooltipContent>
                          {errors.username.message}
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  )}
                </Input>
                {errors.username && (
                  <p className="sr-only" id="username-error" role="alert">
                    {errors.username.message}
                  </p>
                )}
              </div>
              <div>
                <PasswordInput
                  {...register("password")}
                  label="Password"
                  id="password"
                  aria-invalid={!!errors.password}
                  aria-describedby="password-error"
                >
                  {errors.password && (
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger>
                          <ErrorIcon className="text-error size-4" />
                        </TooltipTrigger>
                        <TooltipContent>
                          {errors.password.message}
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  )}
                </PasswordInput>
                {errors.password && (
                  <p className="sr-only" id="password-error" role="alert">
                    {errors.password.message}
                  </p>
                )}
              </div>
              <p className="flex justify-end gap-2 text-right text-sm !leading-4.5">
                <span>Forgot password?</span>
                <ContactUsLink />
              </p>
              <FlipButton
                disabled={loading}
                label={loading ? "Logging in..." : "Log in"}
                className="!mt-6"
              />
            </form>
          </div>

          <div className="absolute inset-y-0 right-0 -z-10 overflow-hidden">
            <img
              src="/condukt-shape-login-1.svg"
              alt=""
              className="translate-y-[-7.5%]"
            />
          </div>
          <div className="absolute bottom-0 left-0 -z-10">
            <img src="/condukt-shape-login-2.svg" alt="" />
          </div>
        </main>
      </div>
    </>
  );
};

export default Login;
